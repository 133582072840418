import React from 'react';
import '../styles.css';
const Store = () => {
    return (
        <section id="store" className="section store-section">
            <h2> Coming soon!</h2>
        </section>
    );
};

export default Store;