import React from 'react';
import '../styles.css';

const Home = () => {
    return (
        <section id="home" className="section home-section">
            <br></br>
            <br></br>
            <h3> Abstract and Contemporary Art</h3>
            <img src="/TzofitPaintings/TzofitLargePaintings/IMG_8057.jpeg" alt="Artwork" className="home-image" />
            <br></br>
            <img src="/TzofitPaintings/Abstract/IMG_8645.jpg" alt="Artwork" className="home-image" />
            <br></br>
            <img src= "/TzofitPaintings/TzofitLargePaintings/Landscape.jpg " alt="Artwork" className="home-image" />

        </section>
    );
};

export default Home;