import React from 'react';
import '../styles.css';

const Footer = () => {
    return (
        <footer>
            <div className="social-icons">
                <a href="https://www.instagram.com/tzofitofengenden/?hl=en" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.facebook.com/tzofit.ofengenden" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook"></i>
                </a>
            </div>
            <p>&copy; 2024 Tzofit Ofengenden. All rights reserved.</p>
        </footer>
    );
};

export default Footer;