import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Gallery from './components/Gallery';
import About from './components/About';
import Contact from './components/Contact';
import Store from './components/Store';
import Home from './components/Home'; // Assuming Home is also a component\
import Exhibition from './components/Exhibition'; // Assuming Exhibition is also a component
import Footer from './components/Footer'; // Assuming Footer is also a component
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
    return (
        <Router>
            <NavBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/gallery/2024" element={<Gallery />} />
                <Route path="/about" element={<About />} />
                <Route path="/store" element={<Store />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/exhibition" element={<Exhibition />} />
            </Routes>
            <Footer></Footer>

        </Router>
    );
}

export default App;