import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS library
import '../styles.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        from_name: '',  // Changed to match template
        reply_to: '',   // Changed to match template
        message: ''
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // EmailJS configuration
        const serviceID = 'service_fe5vvvr';
        const templateID = 'template_btklpob';
        const userID = 'YoAg6vMFxzWSRVivA';

        emailjs.send(serviceID, templateID, formData, userID)
            .then((response) => {
                setSuccessMessage('Message sent successfully!');
                setErrorMessage('');
                setFormData({ from_name: '', reply_to: '', message: '' }); // Reset form
            })
            .catch((error) => {
                setErrorMessage('Failed to send message. Please try again.');
                setSuccessMessage('');
            });
    };

    return (
        <section id="contact" className="section contact-section">
            <h2>Contact</h2>
            
            {/* Contact Form */}
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="from_name">Your Name</label>
                    <input
                        type="text"
                        id="from_name"
                        name="from_name"  // Updated name to match formData key
                        value={formData.from_name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="reply_to">Your Email</label>
                    <input
                        type="email"
                        id="reply_to"
                        name="reply_to"  // Updated name to match formData key
                        value={formData.reply_to}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="message">Your Message</label>
                    <textarea
                        id="message"
                        name="message"
                        rows="5"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                </div>

                <button type="submit" className="btn-submit">Send Message</button>
            </form>

            {/* Success/Error Messages */}
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </section>
    );
};

export default Contact;