import React from 'react';
import '../styles.css';

const About = () => {
    return (
        <section id="about" className="section about-section">
            <div className="about-box">
            <div className="about-header">
                    {/* Profile Photo */}
                    <img 
                        src="/portrait2.jpg" 
                        alt="Profile of Tzofit" 
                        className="profile-photo"
                    />
                    
                </div>
            <p>I am an academic teaching Ethics and Bioethics at the School of Medicine, Tulane University, 
                where I have lived for the past few years. I completed my PhD in Tübingen, Germany, 
                and worked as a research fellow in Melbourne, Australia. 
                Additionally, I taught for a semester in Guangzhou, China. </p>
            <br />
            <p>Alongside my academic pursuits, I am an abstract painter. 
                My artistic journey began with a focus on realism, but I later transitioned into film and
                 cinema before fully embracing philosophy and academia. Ultimately, I returned to art, 
                 where my work now centers on abstraction. </p> 
                 <br />
            <p>My artistic practice explores the dialogue between color, texture, and form, 
                driven by a desire to transcend the material world. 
                Each piece serves as a quest to expand the boundaries of perception, 
                inviting viewers into a space where the finite and infinite converge. 
                Through abstraction and an experimental approach, 
                I strive to unlock the limitless potential within color and material, 
                allowing every brushstroke to offer viewers a glimpse into their own inner worlds.</p>

            </div>
        </section>
    );
};

export default About;