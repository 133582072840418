import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';

const NavBar = () => {
    return (
        <nav className="navbar">
            {/* Container to align the profile image and title */}
            <div className="logo-container">
                <div className="profile-image">
                    <img src="/protrait3.jpg" alt="Profile" />
                </div>
                <div className="logo">Tzofit Ofengenden</div>
            </div>
            <ul className="nav-links">
                <li><Link to="/">Home</Link></li>

                {/* Dropdown for Gallery */}
                <li className="dropdown">
                    <button className="dropbtn">Gallery</button>
                    <ul className="dropdown-content">
                        <li><Link to="/gallery/2024">2024</Link></li>
                    </ul>
                </li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/exhibition">Exhibition</Link></li>
                <li><Link to="/contact">Contact</Link></li>
                <li><Link to="/store">Store</Link></li>
                
                
            </ul>
        </nav>
    );
};

export default NavBar;