import React from 'react';
import '../styles.css';

const Exhibition = () => {
    return (
        <section id="exhibition" className="exhibition-section">
            <div className="exhibition-box">
                <h2 className="exhibition-title">Art for Art's Sake</h2>
                <h3 className="exhibition-location">Location: Magazine Street, New Orleans</h3>
                <h3 className="exhibition-dates">Dates:</h3>
                <p className="exhibition-info">October 5th, 2024</p>
                <h3 className="exhibition-cost">Cost:</h3>
                <p className="exhibition-info">Free</p>
                <a href="https://magazinestreet.com/event/art-for-art-sake/" className="exhibition-link" target="_blank" rel="noopener noreferrer">Learn more</a>
            </div>
        </section>
    );
};

export default Exhibition;