import React, { useState } from 'react';
import '../styles.css';

const Gallery = () => {
    // Sample images for each category
    const landscapeImages = [
        { src: "/TzofitPaintings/Landscape/Landscape48x72.jpg", description: "Acrylic on Canvas 72 x 48 inches", loading: "lazy" },
        { src: "/TzofitPaintings/Abstract/Acrylic_on_wood_panel_30x40_(76x101_cm).jpg", description: "Acrylic on Wood Panel 30 x 40 inches", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/EcocalypseAcryliconCanvas60x72in(150x180cm).jpg", description:"Ecoalpyse Arcylic on Canvas 60 x 72 inches (150 x 180 cm)", loading: "lazy"},
        
        { src: "/TzofitPaintings/Landscape/IMG_8027.jpeg", description: "Acrylic on Canvas 48 x 36 inches", loading: "lazy" }, // No description, fallback to filename
        { src: "/TzofitPaintings/Abstract/IMG_8584.jpg", description: "Acrylic on Canvas 72 x 48 inches", loading: "lazy" },
        { src: "/TzofitPaintings/Landscape/Landscape.jpg", description: "Acrylic on Canvas 36 x 48 inches", loading: "lazy" },
        { src: "/TzofitPaintings/Landscape/AcryliconCanvas10x20inches.jpeg", description: "Acrylic on Canvas 10 x 20 inches (Sold)", loading: "lazy" },

        { src: "/TzofitPaintings/Abstract/Acrylic_on_canvas_24x36in.jpg", description: "Acrylic on Canvas 24 x 36 inches", loading: "lazy" },

        
    ];
    
    const patternImages = [
            { src: "/TzofitPaintings/Patterns/patterns1.jpg", description: "Acrylic on Canvas", title: "A Figure in Orange" },

        ];


    const realmsImages = [
        { src: "/TzofitPaintings/Realms/RealmsAcryliconCanvas60x72in(150x180cm).jpeg", description: "Acrylic on Canvas 60 x 72 inches (150 x 180 cm)" },
        { src: "/TzofitPaintings/Realms/Acryliconcanvas10x20in(25x50cm)3.jpeg", description: "Acrylic on Canvas 10 x 10 inches (25 x 50 cm)" },
        { src: "/TzofitPaintings/Realms/Acryliconwoodpanel24x36in(60x90cm).jpg", description: "Acrylic on wood panel 24 x 36 inches (60 x 90 cm)" }
        
    ];
    const abstractImages = [
        { src:  "/TzofitPaintings/Abstract/abstractBlue.jpeg", description:"Arcylic on Canvas 36x 48 inches", loading: "lazy"},
        { src:  "/TzofitPaintings/Abstract/IMG_8645.jpg", description:"Arcylic on Canvas 30 x 40 inches", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/Acrylic_on_wood_panel_11x15in.jpg", description:"Acrylic on Wood Panel 11 x 15 inches", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/Acrylic_on_wood_panel_30x40in_(76x101_cm)_heic.jpg", description:"Acrylic on Wood Panel 30 x 40 inches", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/Acrylic_on_wood_panel_30x40in_(76x101_cm)heic.jpg", description:" Acrylic on Wood Panel 30 x 40 inches", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/IMG_7694.jpg", description:"Acrylic on Canvas 18 x 36 inches", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/IMG_7699.jpg", description:"Acrylic on Canvas 10 x 20 inches (25 x 50 cm)", loading: "lazy"},
        { src:  "/TzofitPaintings/Abstract/IMG_7735.jpg", description:"Acrylic on Paper 17 x 20.5 inches "},
        { src: "/TzofitPaintings/Abstract/IMG_7737.jpg", description:"Acrylic on Paper 22 x 30 inches", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/IMG_8003.jpeg", description:"Acrylic on Canvas 16 x 20 inches ", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/IMG_8009.jpg", description:"Arcylic on Canvas 16 x 20 inches", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/IMG_8059.jpeg", description:"Arcylic on Canvas 72 x 48 inches", loading: "lazy"},

        { src: "/TzofitPaintings/Abstract/IMG_8014.jpeg", description:"Arcylic on Woon Panel 24 x 36 inches", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/IMG_8019.jpg", description:"Arcylic on Canvas", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/IMG_8039.jpeg", description:"Arcylic on Canvas 48 x 72 inches", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/IMG_8062.jpeg", description:"Arcylic on Canvas 18 x 36 inches", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/IMG_8074.jpeg", description:"Arcylic on Canvas 8 x 12 inches", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/Acrylic_on_canvas_10x20in_(25x50_cm).jpg", description:"Acrylic on Canvas 10 x 20 inches (25 x 50 cm)", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/Acryliconcanvas10x20in.(25x50cm)copy.jpg", description:"Acrylic on Canvas 10 x 20 inches (25 x 50 cm)", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/Acryliconcanvas10x20in(25x50cm)2.jpeg", description:"Acrylic on Canvas 10 x 20 inches (25 x 50 cm)", loading: "lazy"},
        { src: "/TzofitPaintings/Abstract/IMG_7675.jpg", description:"Acrylic on Canvas 10 x 20 inches (25 x 50 cm)", loading: "lazy"},

    
    ];

    // State to track which gallery is currently active
    const [activeGallery, setActiveGallery] = useState('landscape');

    const getImageClass = (imageUrl) => {
        const img = new Image();
        img.src = imageUrl;
    
        return img.width > img.height ? 'landscape' : 'portrait';
    };
    // Function to extract description from image filename
    const getDescriptionFromFilename = (filename) => {
        const parts = filename.split('/').pop().split('.'); // Get the name without the extension
        let title = parts[0]; // Extract the file name (without extension)

        // Handle common separators like underscores and dashes
        title = title.replace(/[_-]/g, ' '); 

        // Handle camelCase and PascalCase by adding spaces between words
        title = title.replace(/([a-z])([A-Z])/g, '$1 $2');

        // If no title can be determined, fall back to 'Untitled'
        return title.length > 0 ? title : 'Untitled';
    };

    // Render images for the selected category
    const renderImages = (images) => {
        return (
            <div className="image-grid">
                {images.map((image, index) => (
                    <div className="image-item" key={index}>
                        <img src={image.src} alt={`Painting ${index + 1}`} className={`gallery-image ${getImageClass(image.src)}`} />
                        {/* Display the title above the image */}
                    <h4 className="image-title">
                        {image.title}
                    </h4>                      
                        <p className="image-description">
                            {image.description ? image.description : getDescriptionFromFilename(image.src)}
                        </p>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <section id="gallery" className="section gallery-section">
            <h2>2024 Series</h2>

            {/* Navigation Buttons */}
            <div className="scroll-navigation">
            <button onClick={() => setActiveGallery('landscape')}>
                    Landscape
                </button>
                <button onClick={() => setActiveGallery('abstract')}>
                    Abstract
                </button>
               
                <button onClick={() => setActiveGallery('realms')}>
                    Realms
                </button>
                <button onClick={() => setActiveGallery('patterns')}>
                    Patterns
                </button>
            </div>

            <br />
            <br />

            {/* Gallery Rendering */}
            {activeGallery === 'landscape' && (
                <div className="gallery-section-title">
                    <h3>Landscape</h3>
                    {renderImages(landscapeImages)}
                </div>
            )}
            {activeGallery === 'abstract' && (
                <div className="gallery-section-title">
                    <h3>Abstract</h3>
                    {renderImages(abstractImages)}
                </div>
            )}
            

            {activeGallery === 'realms' && (
                <div className="gallery-section-title">
                    <h3>Realms</h3>
                    {renderImages(realmsImages)}
                </div>
            )}
            {activeGallery === 'patterns' && (
                <div className="gallery-section-title">
                    <h3>Patterns</h3>
                    {renderImages(patternImages)}
                </div>
            )}
        </section>
    );
};

export default Gallery;